import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

i18n
  .use(HttpBackend) // Load translation files from the backend
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language if translation not found
    backend: {
      loadPath: '/locales/{{lng}}/translation.json' // Path to translation files
    },
    interpolation: {
      escapeValue: false // React already safes from xss
    },
    react: {
      useSuspense: false // Optional: Prevents loading translation on initial render
    }
  });

export default i18n;
