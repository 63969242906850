import React, { useState } from 'react';
import { IoMdArrowDropdown } from "react-icons/io";
import { useTranslation } from 'react-i18next';
import britishFlag from '../../assets/images/640px-Flag_of_the_United_Kingdom_(1-2).svg.webp';
import uaeFlag from '../../assets/images/UAE Flag.png';
import franceFlag from '../../assets/images/640px-Flag_of_France.svg.png';

const LanguageTranslation = ({ className }) => {
    const { i18n } = useTranslation();

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [languageName, setLanguageName] = useState(i18n.language === 'ar' ? 'AR' : i18n.language === 'fre' ? 'FR' : 'EN');
    const [imageFlag, setImageFlag] = useState(britishFlag);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const languageSelectHandler = (lang) => {

        if (lang === 'en') {
            i18n.changeLanguage(lang);
            setLanguageName('EN');
            setImageFlag(britishFlag);

        } else if (lang === 'ar') {
            i18n.changeLanguage(lang);
            setLanguageName('AR');
            setImageFlag(uaeFlag);

        } else if (lang === 'fre') {
            i18n.changeLanguage(lang);
            setLanguageName('FR');
            setImageFlag(franceFlag);
        }

    };

    return (
        <div className={`changeDropdown m-0 ${className}`}>
            <div className={`dropdown ${className}`}>
                <button onClick={toggleDropdown} className={`dropbtn text-center ${className}`}>
                    <img src={imageFlag} alt='' width='25px' className='me-2' />
                    {languageName}
                    <IoMdArrowDropdown className=" ms-1" />
                </button>
                {dropdownVisible && (
                    <div id="myDropdown1" className="dropdown-content">
                        <div className='d-flex align-items-center justify-content-center' onClick={() => { languageSelectHandler('en'); toggleDropdown() }}>
                            <img src={britishFlag} alt='' width='25px' className='me-2' />
                            English
                        </div>
                        <div className='d-flex aling-items-center justify-content-center' onClick={() => { languageSelectHandler('ar'); toggleDropdown() }}>
                            <img src={uaeFlag} alt='' width='25px' className='me-2' />
                            ArabIc
                        </div>
                        <div className='d-flex align-items-center justify-content-center' onClick={() => { languageSelectHandler('fre'); toggleDropdown() }}>
                            <img src={franceFlag} alt='' width='25px' className='me-2' />
                            French
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default LanguageTranslation
